<template>
  <div class="leftColumn">
    <graphTitle title="会员支付占比">
      <el-tooltip
        placement="top"
        content="展示统计时间内，会员支付金额与非会员支付金额的对比。会员支付订单数与非会员支付订单数的对比。按支付时间统计。"
      >
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="piea" :style="{ height: '260px' }"></div>
    <!-- 实收总额 -->
    <p
      style="text-align: center; color: rgba(0, 0, 0, 0.65); font-size: 17px"
      v-if="this.number == 0"
    >
      收入总额：{{
        ((Number(pieData.memberPay) + Number(pieData.nonmemberPay)) / 100).toFixed(2) | toThousands
      }}元
    </p>
    <p
      style="text-align: center; color: rgba(0, 0, 0, 0.65); font-size: 17px"
      v-if="this.number == 1"
    >
      实收订单：{{ Number(pieData.memberPayCount) + Number(pieData.nonmemberPayCount) || 0 }}个
    </p>
    <div class="switch">
      <p :class="number == 0 ? 'add-style' : 'default-style'" @click="gold" style="cursor: pointer">
        收入金额
      </p>
      <p
        :class="number == 0 ? 'default-style1' : 'add-style1'"
        @click="pen"
        style="cursor: pointer"
      >
        订单数量
      </p>
    </div>
  </div>
</template>
<script>
import graphTitle from "@/components/graphTitle";
export default {
  props: {
    pieData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
  },
  name: "Carbinding",
  components: {
    graphTitle,
  },
  data() {
    return {
      number: 0,
      calculate: 0,
      Promptbox: "",
      PromptboxTwo: "",
      pie1: [
        { value: 0, name: "会员" },
        { value: 0, name: "非会员" },
      ],
      pie2: [
        { value: 0, name: "会员" },
        { value: 0, name: "非会员" },
      ],
    };
  },
  filters: {
    toThousands(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
  },
  watch: {
    pieData: {
      handler(value) {
        if (value.length <= 0) {
          this.pie1 = [
            { value: 0, name: "线上支付" },
            { value: 0, name: "线下支付" },
          ];
          this.pie2 = [
            { value: 0, name: "线上支付" },
            { value: 0, name: "线下支付" },
          ];
          // this.calculate = 0;
          this.setPieA();
        } else {
          this.pie1 = [];
          this.pie2 = [];
          this.number = 0;
          // this.calculate = 0;
          // 金额
          this.pie1.push(
            { value: (value.memberPay / 100).toFixed(2), name: "会员" },
            { value: (value.nonmemberPay / 100).toFixed(2), name: "非会员" }
          );
          // this.calculate = parseInt(this.pie1[1].value / (this.pie1[0].value + this.pie1[1].value) * 100) + '%';
          // 笔数
          this.pie2.push(
            { value: value.memberPayCount, name: "会员" },
            { value: value.nonmemberPayCount, name: "非会员" }
          );
          this.setPieA();
        }
      },
      deep: true,
    },
  },
  methods: {
    toThousands1(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
    },
    gold() {
      this.number = 0;
      // this.calculate = 0;
      // if (this.pie1[0].value == '') {
      //   this.calculate = 0;
      // } else {
      //   this.calculate = parseInt(this.pie1[1].value / (this.pie1[0].value + this.pie1[1].value) * 100) + '%';
      // }
      // console.log(this.calculate)
      this.setPieA();
    },
    format(num) {
      return (num + "").replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
    },
    pen() {
      this.number = 1;
      // this.calculate = 0;
      // if (this.pie2[0].value == '') {
      //   this.calculate = 0;
      // } else {
      //   this.calculate = parseInt(this.pie2[1].value / (this.pie2[0].value + this.pie2[1].value) * 100) + '%';
      // }
      // console.log(this.calculate)
      this.setPieA();
    },
    setPieA() {
      // let data = [
      //   {
      //     value: parseInt(100 * Math.random()),
      //     name: '未绑定车牌数'
      //   }, {
      //     value: parseInt(100 * Math.random()),
      //     name: '绑定车牌数'
      //   }
      // ]
      let that = this;
      let option = {
        color: ["#FFA924", "#51A1F3"],
        // title: {
        //   text: '金额 | 笔数',
        //   left: 'left',
        //   top: '0',
        //   padding: [0, 25, 0, 0],
        //   textStyle: {
        //     fontSize: 14
        //   }
        // },
        tooltip: {
          trigger: "item",
          padding: [15, 15, 15, 30],
          // formatter: "{a}: {c}元 <br/>占比: {d}%"
          // formatter: that.number == 0 ? that.Promptbox : that.number == 1 ? that.PromptboxTwo : that.Promptbox
          formatter: (params) => {
            if (this.number == 0) {
              let tipText = `<span>${params.marker}${params.name}</span>
								<p>
									<span style="position: absolute;left: 10px;"></span>支付金额: ${this.format(params.value)}(元)
								</p>
								<p>
									<span style="position: absolute;left: 10px;"></span>占比：${params.percent}%
								</p>
              `;
              return tipText;
            } else {
              let tipText = `<span>${params.marker}${params.name}</span>
								<p>
									<span style="position: absolute;left: 10px;"></span>支付订单${params.value}(个)
								</p>
								<p>
									<span style="position: absolute;left: 10px;"></span>占比：${params.percent}%
								</p>
              `;
              return tipText;
            }
          },
        },
        legend: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          data: [
            {
              name: "会员",
              icon: "circle",
            },
            {
              name: "非会员",
              icon: "circle",
            },
          ],
        },
        // graphic: {
        //   type: 'text',
        //   left: 'center',
        //   top: '105px',
        //   z: 2,
        //   zlevel: 100,
        //   style: {
        //     text: this.calculate,
        //     // text: this.number == 0 ? this.calculate : this.number == 1 ? this.calculate : 0,
        //     fill: '#000',
        //     width: 100,
        //     height: 30,
        //     font: 'bolder 25px Microsoft YaHei'
        //   }
        // },
        series: [
          {
            center: ["50%", "45%"],
            name: "线上支付",
            type: "pie",
            radius: ["65%", "80%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold",
                },
                formatter(params) {
                  return params.name;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            // data: that.pie1
            data: that.number == 0 ? that.pie1 : that.number == 1 ? that.pie2 : that.pie1,
          },
        ],
      };
      let myChart = this.$echarts.init(document.getElementById("piea"));
      myChart.setOption(option);
    },
  },
  created() {
    this.Promptbox = "{a}: {c}元 <br/>占比: {d}%";
    this.PromptboxTwo = "{a}: {c}条 <br/>占比: {d}%";
  },
  mounted() {
    this.setPieA();
  },
};
</script>
<style scoped>
.leftColumn {
  position: relative;
}
.switch {
  position: absolute;
  right: 20px;
  top: 10px;
  height: 32px;
  line-height: 32px;
}
.switch p {
  width: 76px;
  float: left;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
}
.switch p.active {
  background: #527bff;
}
.switch .add-style {
  background: rgba(6, 116, 251, 0.12);
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #0474fb;
  color: #1c81fb;
}
.switch .default-style {
  background: #ffffff;
  border-radius: 4px 0px 0px 4px;
  border: 1px solid #c0c6cc;
  color: #333333;
}
.switch .add-style1 {
  background: rgba(6, 116, 251, 0.12);
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #0474fb;
  color: #1c81fb;
}
.switch .default-style1 {
  background: #ffffff;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #c0c6cc;
  color: #333333;
}
</style>
